<template>
    <div class="video-wrapper">
        <!-- primera capa -->
        <div class="outer-container"></div>
        <!-- segunda capa -->
        <div class="inner-container">
            <!-- Video -->
            <video class="video" autoplay loop muted playsinline controls>
                <source src="@/assets/images/Video_Fresher.mp4" type="video/mp4" />
                Tú navegador no soporta este video.
            </video>
        </div>
    </div>
</template>

<script>
export default {
    name: "VideoSection",
};
</script>

<style scoped>
.video-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100vh; 
    background-color: white; 
    position: relative;
}

.outer-container {
    background-color: #61A3D9;
    width: 100%;
    height: 50vh; 
    position: absolute;
    bottom: 0; 
    left: 0; 
    z-index: 0; 
}

.inner-container {
    background-color: #61A3D9;
    width: 90%; 
    height: 95vh; 
    padding: 40px; 
    border-radius: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; 
    z-index: 1; 
}

.video {
    width: 100%; 
    height: 95%;
    border-radius: 20px;
}
</style>
